<template>
  <div id="testimonials">
    <div class="container">
      <h1>{{ t('KHACH_HANG_NGHI_GI_VE_CHUNG_TOI') }}</h1>

      <div class="testimonial-text">
        <slick :options="slickTextOptions" ref="slickTextRef" class="nav-sync">
          <div class="slide" v-for="testimonial in testimonialItems" :key="testimonial.item">
            <div class="quote">
              <svg width="49" height="38" viewBox="0 0 49 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.9 16.3L21.6 0H9.7L3.3 14.7C0.4 21.1 0 23.3 0 26.1C0 32.9 4.8 37.3 11.4 37.3C17.9 37.3 22.8 32.9 22.8 26.1C22.8 21.4 20.5 18 16.9 16.3ZM43.1 16.3L47.8 0H35.9L29.5 14.7C26.6 21.1 26.2 23.3 26.2 26.1C26.2 32.9 31 37.3 37.6 37.3C44.1 37.3 49 32.9 49 26.1C49 21.4 46.7 18 43.1 16.3Z" fill="#E11F27"/>
              </svg>
            </div>

            <div class="text">{{ testimonial.content }}</div>

            <div class="quote right">
              <svg width="49" height="38" viewBox="0 0 49 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.5 0C4.89998 0 0.0999756 4.4 0.0999756 11.2C0.0999756 15.9 2.39998 19.3 5.99998 21L1.29998 37.3H13.2L19.6 22.7C22.4 16.2 22.8 14 22.8 11.2C22.8 4.4 18 0 11.5 0ZM37.7 0C31.1 0 26.3 4.4 26.3 11.2C26.3 15.9 28.6 19.3 32.2 21L27.5 37.3H39.4L45.8 22.7C48.6 16.2 49 14 49 11.2C49 4.4 44.2 0 37.7 0Z" fill="#E11F27"/>
              </svg>
            </div>
          </div>
        </slick>
      </div>

      <div class="testimonial-customer">
        <slick :options="slickCustomerOptions" ref="slickCustomerRef" class="nav-sync">
          <div class="slide" v-for="testimonial in testimonialItems" :key="testimonial.item">
            <h3>{{ testimonial.name }}</h3>
            <small>{{ testimonial.item }}</small>
          </div>
        </slick>
      </div>

      <div class="testimonials-list">
        <slick :options="slickOptions" ref="slickRef" @beforeChange="setFocusedTestimonial">
          <div class="slide" v-for="testimonial in testimonialItems" :key="testimonial.item">
            <div class="testimonial-item">
              <img :src="testimonial.photo" :alt="testimonial.name"/>
            </div>
          </div>
        </slick>

        <div class="arrow left" ref="arrowLeft">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill="#2E2E38"/>
          </svg>
        </div>

        <div class="arrow right" ref="arrowRight">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" fill="#2E2E38"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import Slick from '@/components/Slick';

const testimonialItems = [
  {
    name: 'Chị Vân',
    photo: 'https://picsum.photos/201',
    item: 'Khách hàng cầm túi Hermes để kinh doanh quán cafe ở quận 7',
    content: 'Tôi bắt đầu kinh doanh quán cafe nhưng còn thiếu một ít tiền, lại cần rất gấp. May nhờ một người bạn giới thiệu cho tôi Camdonhanh làm thủ tục rất nhanh chỉ trong vòng chưa đầy một tiếng. Từ đó mỗi lần cần tiền là tôi lại nghĩ đến Camdonhanh đầu tiên.'
  },
  {
    name: 'Anh Sơn',
    photo: 'https://picsum.photos/202',
    item: 'Khách hàng cầm xe Toyota Prado để đấu thầu dự án trường Tiểu học tại Bến Tre',
    content: 'Tôi vừa đấu thầu thành công 1 dự án lớn cũng nhờ được hỗ trợ tài chính kịp thời từ Camdonhanh. Sắp tới nếu có nhu cầu để xoay vốn nhanh chóng thì Camdonhanh chính là sự lựa chọn phù hợp nhất cho tôi.'
  },
  {
    name: 'Chị Trang',
    photo: 'https://picsum.photos/203',
    item: 'Khách hàng cầm xe Liberty S để đóng tiền học phí cho con',
    content: 'Con bé nhà tôi lại đến kỳ đóng học phí mà gia đình chưa xoay sở được tiền, đang đau đầu vì không biết phải làm sao để có tiền nhanh chóng để đóng cho con. Vô tình con bé lên Facebook và biết được dịch vụ bên mình nên có bảo tôi tham khảo thử. Không ngờ nhân viên Camdonhanh tư vấn, thẩm định rồi giải ngân chỉ trong một buổi sáng. Quá tuyệt vời! Tôi nhất định sẽ quay lại khi có nhu cầu.'
  },
  {
    name: 'Chị Vân',
    photo: 'https://picsum.photos/201',
    item: 'Khách hàng cầm túi Hermes để kinh doanh quán cafe ở quận 7',
    content: 'Tôi bắt đầu kinh doanh quán cafe nhưng còn thiếu một ít tiền, lại cần rất gấp. May nhờ một người bạn giới thiệu cho tôi Camdonhanh làm thủ tục rất nhanh chỉ trong vòng chưa đầy một tiếng. Từ đó mỗi lần cần tiền là tôi lại nghĩ đến Camdonhanh đầu tiên.'
  },
  {
    name: 'Anh Sơn',
    photo: 'https://picsum.photos/202',
    item: 'Khách hàng cầm xe Toyota Prado để đấu thầu dự án trường Tiểu học tại Bến Tre',
    content: 'Tôi vừa đấu thầu thành công 1 dự án lớn cũng nhờ được hỗ trợ tài chính kịp thời từ Camdonhanh. Sắp tới nếu có nhu cầu để xoay vốn nhanh chóng thì Camdonhanh chính là sự lựa chọn phù hợp nhất cho tôi.'
  },
  {
    name: 'Chị Trang',
    photo: 'https://picsum.photos/203',
    item: 'Khách hàng cầm xe Liberty S để đóng tiền học phí cho con',
    content: 'Con bé nhà tôi lại đến kỳ đóng học phí mà gia đình chưa xoay sở được tiền, đang đau đầu vì không biết phải làm sao để có tiền nhanh chóng để đóng cho con. Vô tình con bé lên Facebook và biết được dịch vụ bên mình nên có bảo tôi tham khảo thử. Không ngờ nhân viên Camdonhanh tư vấn, thẩm định rồi giải ngân chỉ trong một buổi sáng. Quá tuyệt vời! Tôi nhất định sẽ quay lại khi có nhu cầu.'
  },
];

const slickCustomerOptions = {
  arrows: false,
  autoplay: false,
  infinite: true,
  slidesToShow: 1,
  centerMode: true,
  centerPadding: '60px',
  speed: 500,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerPadding: 0,
      }
    },
    {
      breakpoint: 480,
      settings: {
        centerPadding: 0,
      }
    }
  ]
};

const slickTextOptions = {
  arrows: false,
  autoplay: false,
  infinite: true,
  fade: true,
  slidesToShow: 1,
  speed: 200,
  cssEase: 'linear',
};

export default {
  name: 'Testimonials',
  components: {
    Slick
  },
  setup() {
    const { t } = useI18n();
    const focusedTestimonial = ref(testimonialItems[0]);
    const arrowLeft = ref(null);
    const arrowRight = ref(null);
    const slickTextRef = ref(null);
    const slickCustomerRef = ref(null);

    const slickOptions = computed(() => ({
      arrows: true,
      autoplay: true,
      infinite: true,
      centerMode: true,
      centerPadding: '60px',
      slidesToShow: 3,
      speed: 500,
      cssEase: 'linear',
      prevArrow: arrowLeft.value,
      nextArrow: arrowRight.value,
      asNavFor: '.nav-sync',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            centerPadding: 0,
            slidesToShow: 3
          }
        }
      ]
    }));

    const setFocusedTestimonial = (current, next) => {
      focusedTestimonial.value = testimonialItems[next];
    };

    return {
      t,
      testimonialItems,
      focusedTestimonial,
      slickOptions,
      arrowLeft,
      arrowRight,
      setFocusedTestimonial,
      slickTextOptions,
      slickTextRef,
      slickCustomerOptions,
      slickCustomerRef
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#testimonials {
  padding: 90px 0;
  text-align: center;

  h1 {
    color: $geranium;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .container {
    max-width: 960px;
  }

  .testimonial-text {
    .slide {
      display: flex;
      justify-content: space-between;
    }

    .quote {
      vertical-align: top;

      @media (max-width: 767px) {
        svg {
          width: 32px;
        }
      }
    }

    .text {
      vertical-align: top;
      font-size: 24px;
      color: $independence;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }

  .testimonial-customer {
    text-align: center;
    margin-top: 30px;

    h3 {
      font-weight: 700;
      font-size: 20px;
    }

    small {
      color: $grey;
    }
  }

  .testimonials-list {
    margin-top: 30px;
    padding: 0 60px;
    position: relative;

    .slide {
      text-align: center;
    }

    .testimonial-item {
      border-radius: 50%;
      width: 110px;
      height: 110px;
      transform: scale(0.8);
      border: 5px solid $white;
      overflow: hidden;
      display: inline-block;
      transition: all .5s ease-in-out;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media (max-width: 767px) {
        width: 80px;
        height: 80px;
      }
    }

    .arrow {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background-color: $aliceBlue;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      padding-top: 5px;
      cursor: pointer;
      transition: all .2s ease-in-out;

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }

      &:hover {
        opacity: .7;
        transition: all .2s ease-in-out;
      }
    }

    .slick-current {
      .testimonial-item {
        transform: scale(1);
        border-color: $geranium;
      }
    }
  }
}
</style>
