<template>
  <div id="branches">
    <div class="container">
      <h1>{{ t('CHI_NHANH') }}</h1>

      <div class="row branches" :class="{ dropdown: (isMobile && mobileBranchesDropdown) }">
        <div class="col col-12 col-lg-6" v-for="branch in branches" :key="branch.name">
          <div class="branch-item" :class="{ active: (currentMap.name === branch.name) }" @click="(isMobile && !mobileBranchesDropdown) ? showBranches() : selectMap(branch)">
            <div class="icon">
              <svg width="48" height="42" viewBox="0 0 48 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M47.0946 13.7433L44.6446 3.54667C44.1313 1.44667 42.3113 0 40.188 0H7.77798C5.67798 0 3.83464 1.47 3.34464 3.54667L0.894644 13.7433C0.334644 16.1233 0.847978 18.55 2.34131 20.4633C2.52798 20.72 2.78464 20.9067 2.99464 21.14V37.3333C2.99464 39.9 5.09464 42 7.66131 42H40.328C42.8946 42 44.9946 39.9 44.9946 37.3333V21.14C45.2046 20.93 45.4613 20.72 45.648 20.4867C47.1413 18.5733 47.678 16.1233 47.0946 13.7433ZM40.118 4.64333L42.568 14.84C42.8013 15.82 42.5913 16.8 41.9846 17.57C41.658 17.99 40.958 18.6667 39.7913 18.6667C38.368 18.6667 37.1313 17.5233 36.968 16.0067L35.6146 4.66667L40.118 4.64333ZM26.328 4.66667H30.9013L32.1613 15.2133C32.278 16.1233 31.998 17.0333 31.3913 17.71C30.878 18.3167 30.1313 18.6667 29.1746 18.6667C27.6113 18.6667 26.328 17.29 26.328 15.61V4.66667ZM15.8046 15.2133L17.088 4.66667H21.6613V15.61C21.6613 17.29 20.378 18.6667 18.6513 18.6667C17.858 18.6667 17.1346 18.3167 16.5746 17.71C15.9913 17.0333 15.7113 16.1233 15.8046 15.2133ZM5.42131 14.84L7.77798 4.66667H12.3746L11.0213 16.0067C10.8346 17.5233 9.62131 18.6667 8.19798 18.6667C7.05464 18.6667 6.33131 17.99 6.02798 17.57C5.39798 16.8233 5.18798 15.82 5.42131 14.84ZM7.66131 37.3333V23.2633C7.84798 23.2867 8.01131 23.3333 8.19798 23.3333C10.228 23.3333 12.0713 22.4933 13.4246 21.1167C14.8246 22.5167 16.6913 23.3333 18.8146 23.3333C20.8446 23.3333 22.6646 22.4933 24.018 21.1633C25.3946 22.4933 27.2613 23.3333 29.3613 23.3333C31.3213 23.3333 33.188 22.5167 34.588 21.1167C35.9413 22.4933 37.7846 23.3333 39.8146 23.3333C40.0013 23.3333 40.1646 23.2867 40.3513 23.2633V37.3333H7.66131Z" fill="#C51B21"/>
              </svg>
            </div>
            <div class="text">
              <h3>{{ branch.name }}</h3>
              <small>{{ branch.address }}</small>
            </div>
          </div>
        </div>

        <div class="arrow" v-if="isMobile">
          <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.09 0.294922L6.5 4.87492L1.91 0.294922L0.5 1.70492L6.5 7.70492L12.5 1.70492L11.09 0.294922Z" fill="#2E2E38"/>
          </svg>
        </div>
      </div>

      <div class="map">
        <iframe :src="currentMap.googleMap" style="border:0;" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';

const branches = [
  {
    name: 'Quận 1',
    address: '76 Điện Biên Phủ, Đakao, Quận 1',
    googleMap: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.26780345251!2d106.69509297682754!3d10.79078925892502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317528cae8787ae3%3A0xd9a5f4aa4ac56796!2zNzYgxJAuIMSQaeG7h24gQmnDqm4gUGjhu6csIMSQYSBLYW8sIFF14bqtbiAxLCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmgsIFZpZXRuYW0!5e0!3m2!1sen!2s!4v1707975759570!5m2!1sen!2s'
  },
  {
    name: 'Tân Bình',
    address: '1084 CMT8, Phường 4, Tân Bình',
    googleMap: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.255315655574!2d106.65342661588662!3d10.791747061859041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752934d57840df%3A0x7421905445ab560e!2zMTA4NCDEkC4gQ8OhY2ggTeG6oW5nIFRow6FuZyA4LCBQaMaw4budbmcgNCwgVMOibiBCw6xuaCwgVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1664771459842!5m2!1sen!2s'
  },
  {
    name: 'Quận 12',
    address: '127A Nguyễn Ảnh Thủ, Hiệp Thành, Quận 12',
    googleMap: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.1440564109066!2d106.64253701588686!3d10.876645960307355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529e3b2cfdfad%3A0x3bd98211061b7074!2zMTI3YSDEkC4gTmd1eeG7hW4g4bqibmggVGjhu6csIEhp4buHcCBUaMOgbmgsIFF14bqtbiAxMiwgVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1664771586594!5m2!1sen!2s'
  },
  {
    name: 'Quận 4',
    address: '218 Khánh Hội, Phường 6, Quận 4',
    googleMap: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.6830249431505!2d106.69703241588655!3d10.758894162456462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f1a535cea1f%3A0x7b19363d9be35557!2zMjE4IEtow6FuaCBI4buZaSwgUGjGsOG7nW5nIDYsIFF14bqtbiA0LCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmgsIFZpZXRuYW0!5e0!3m2!1sen!2s!4v1664771646136!5m2!1sen!2s'
  }
];

export default {
  name: 'Branches',
  components: {
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const currentMap = ref(branches[0]);
    const mobileBranchesDropdown = ref(false);

    const isDesktop = computed(() => store.state.app.isDesktop);
    const isMobile = computed(() => store.state.app.isMobile);

    const selectMap = (branch) => {
      currentMap.value = branch;
      mobileBranchesDropdown.value = false;
    };

    const showBranches = () => {
      mobileBranchesDropdown.value = true;
    };

    return {
      t,
      branches,
      currentMap,
      selectMap,
      isDesktop,
      isMobile,
      showBranches,
      mobileBranchesDropdown
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#branches {
  width: 100%;
  padding: 90px 0;
  text-align: center;

  @media (max-width: 767px) {
    padding: 30px 0;
  }

  h1 {
    color: $geranium;
    font-weight: 700;
  }

  .branches {
    text-align: left;
    margin-top: 50px;
    position: relative;
    transition: all .2s ease-in-out;

    @media (max-width: 767px) {
      height: 110px;
      margin-top: 40px;
    }

    .branch-item {
      margin-bottom: 20px;
      transition: all .4s ease-in-out;
      border-radius: 16px;
      cursor: pointer;
      box-shadow: 0px 0px 12px rgba(251, 33, 33, 0.06);
      background-color: $white;

      .icon {
        width: 48px;
        padding: 20px;
        vertical-align: middle;
        display: inline-block;
        margin-right: 40px;
        color: $geranium;
        transition: all .4s ease-in-out;

        svg {
          width: 48px;

          path, polygon {
            fill: currentColor;
          }
        }
      }

      .text {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 100px);

        h3 {
          font-weight: 700;
          font-size: 20px;
          margin-bottom: 0;
        }

        small {
          color: $grey;
          transition: all .4s ease-in-out;
        }
      }

      &:hover, &.active {
        background-color: $geranium;
        color: $white;

        .icon {
          color: $white;
        }

        .text {
          small {
            color: $gainsboro;
          }
        }
      }

      @media (max-width: 767px) {
        position: absolute;
        top: 0;
        left: 15px;
        width: calc(100% - 30px);
        box-shadow: none;
        border: 1px solid $gainsboro;

        .icon {
          width: 40px;
          margin-right: 25px;
          padding: 20px 15px;

          svg {
            width: 40px;
          }
        }

        .text {
          h3 {
            font-size: 18px;
          }
        }

        &.active {
          z-index: 9;

          background-color: $white;
          color: $raisin;

          .icon {
            color: $geranium;
          }

          .text {
            small {
              color: $grey;
            }
          }
        }
      }
    }

    .arrow {
      position: absolute;
      top: 27px;
      right: 30px;
      width: 20px;
      height: 20px;
      z-index: 10;
    }

    &.dropdown {
      height: auto;

      .branch-item {
        position: relative;

        &.active {
          background-color: $geranium;
          color: $white;

          .icon {
            color: $white;
          }

          .text {
            small {
              color: $gainsboro;
            }
          }
        }
      }

      .arrow {
        display: none;
      }
    }
  }

  .map {
    width: 100%;
    height: 400px;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
