<template>
  <div id="blog-content">
    <div class="container blog-container" v-if="article.id">
      <div class="tags-wrapper">
        <div v-for="tag in article.tags" :key="tag.name" class="tag">{{ tag.name }}</div>
      </div>
      <h1>{{ article.title }}</h1>
      <div class="date">
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.333 2.00008H11.6663V0.666748H10.333V2.00008H3.66634V0.666748H2.33301V2.00008H1.66634C0.933008 2.00008 0.333008 2.60008 0.333008 3.33341V14.0001C0.333008 14.7334 0.933008 15.3334 1.66634 15.3334H12.333C13.0663 15.3334 13.6663 14.7334 13.6663 14.0001V3.33341C13.6663 2.60008 13.0663 2.00008 12.333 2.00008ZM12.333 14.0001H1.66634V6.66675H12.333V14.0001ZM12.333 5.33341H1.66634V3.33341H12.333V5.33341Z" fill="#8F8FA3"/>
        </svg>
        <span v-if="$i18n.locale !== 'vi'">{{ moment(article.createdAt).format('DD.MM.YYYY') }}</span>
        <span v-else>{{ `${moment(article.createdAt).format('DD')} tháng ${moment(article.createdAt).format('MM, YYYY')}` }}</span>
      </div>

      <div class="cover-img">
        <img :src="article.thumbnailUrl || defaultThumb" :alt="article.title" />
      </div>

      <div class="content" v-html="article.content" />

      <div class="extras">
        <h2>{{ t('BAI_VIET_MOI') }}</h2>

        <div class="row">
          <div class="col col-12 col-xs-12 col-md-6 col-lg-8">
            <div class="row">
              <div class="col col-12 col-xs-12 col-md-6" v-for="item in firstTwos" :key="item.id">
                <div class="article-item">
                  <div class="thumbnail">
                    <router-link :to="applyParams(PATHS.BLOG_CONTENT, item.slug)">
                      <img :src="item.thumbnailUrl || defaultThumb" :alt="item.title" />
                    </router-link>
                  </div>
                  <div class="text-wrapper">
                    <div class="text">
                      <div class="date">{{ moment(item.createdAt).format('DD.MM.YYYY') }}</div>
                      <h3><router-link :to="applyParams(PATHS.BLOG_CONTENT, item.slug)">{{ item.title }}</router-link></h3>
                      <div class="desc">{{ getDescription(item.summary?.trim(), 120) }}</div>
                      <div class="tags-wrapper">
                        <div v-for="tag in item.tags" :key="tag.name" class="tag">{{ tag.name }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-12 col-xs-12 col-md-6 col-lg-4">
            <div class="article-item column-item" v-for="item in rests" :key="item.id" v-show="isDesktop">
              <div class="thumbnail">
                <router-link :to="applyParams(PATHS.BLOG_CONTENT, item.slug)">
                  <img :src="item.thumbnailUrl || defaultThumb" :alt="item.title" />
                </router-link>
              </div>
              <div class="text-wrapper">
                <div class="text">
                  <div class="date">{{ moment(item.createdAt).format('DD.MM.YYYY') }}</div>
                  <h3><router-link :to="applyParams(PATHS.BLOG_CONTENT, item.slug)">{{ item.title }}</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <router-link :to="PATHS.BLOG"><primary-button :text="t('TAT_CA_BAI_VIET')" inverse class="all-articles" /></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';
import { useRouter, useRoute } from 'vue-router';
import moment from 'moment';

import PrimaryButton from '@/components/PrimaryButton';

import { getDescription, applyParams, scrollToTop } from '@/helpers';
import PATHS from '@/consts/paths';

import defaultThumb from '@/assets/imgs/about-us-cover-min.jpg';

export default {
  name: 'BlogList',
  components: {
    PrimaryButton
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const title = t('BLOG');

    const { meta } = useMeta({
      title,
      og: {
        title: `${title} | Camdonhanh`
      },
    });

    const isDesktop = computed(() => store.state.app.isDesktop);

    const articles = computed(() => store.state.blog.items);
    const slug = computed(() => route.params.slug);
    const article = computed(() => store.state.blog.targetingArticle);

    const firstTwos = computed(() => articles.value.slice(0, 2));
    const rests = computed(() => articles.value.slice(2, articles.value.length));

    onMounted(async () => {
      if (!articles.value.length) {
        store.dispatch('blog/getArticles', false);
      }
    });

    watch(slug, (val_) => {
      if (!val_) return;
      let val = val_.toLowerCase();

      if (val.substring(val.length - 5, val.length) === '.html') {
        val = val.substring(0, val.length - 5);
        window.open(applyParams(PATHS.BLOG_CONTENT, val), '_self');
        return;
      }

      scrollToTop();
      store.dispatch('blog/readArticle', val);
    }, {
      immediate: true
    });

    watch(article, (val) => {
      meta.title = val.title;
      meta.description = val.metaDescription;
      meta.og = {
        title: `${val.metaTitle} | Camdonhanh`,
        description: val.metaDescription,
        image: val.thumbnailUrl,
      };
    });

    return {
      t,
      articles,
      moment,
      getDescription,
      defaultThumb,
      article,
      firstTwos,
      rests,
      applyParams,
      PATHS,
      isDesktop
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#blog-content {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 80px;

  .blog-container {
    max-width: 960px;
  }

  h1 {
    font-weight: 700;
    font-size: 30px;
    margin-top: 7px;
  }

  .date {
    color: $grey;
    font-size: 12px;
    margin-bottom: 5px;

    svg {
      vertical-align: middle;

      path, polygon {
        fill: currentColor;
      }
    }

    span {
      vertical-align: middle;
      margin-left: 7px;
    }
  }

  .tags-wrapper {
    .tag {
      display: inline-block;
      border-radius: 24px;
      padding: 8px 24px;
      text-transform: capitalize;
      margin-right: 15px;
      cursor: pointer;
      transition: all .2s ease-in-out;
      color: $raisin;
      border: 0;
      background-color: $honeyDew;
      font-size: 12px;
      padding-top: 5px;

      &:hover, &.active {
        background-color: $lava;
        color: $white;
      }
    }
  }

  .cover-img {
    width: 100%;
    padding-bottom: 47%;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 7px;
    }
  }

  .content {
    img {
      max-width: 100%;
      height: auto !important;
    }
  }

  .extras {
    margin-top: 50px;
    position: relative;

    .all-articles {
      position: absolute;
      top: 0;
      right: 0;

      @media (max-width: 767px) {
        position: relative;
        width: 100%;
      }
    }

    h2 {
      font-weight: 700;
      font-size: 30px;
      color: $geranium;
      margin-bottom: 30px;
    }

    .article-item {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
      border-radius: 12px;

      @media (max-width: 767px) {
        margin-left: -15px;
        margint-right: -15px;
        width: calc(100% + 30px);
        border-radius: 0;
      }

      .thumbnail {
        border-radius: 12px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        overflow: hidden;
        padding-bottom: 100%;
        position: relative;

        @media (max-width: 767px) {
          border-radius: 0;
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      .text-wrapper {
        margin-top: 15px;
        padding: 15px;

        .text {
          padding-bottom: 15px;

          h3 {
            font-weight: 700;
            font-size: 16px;
            height: 50px;
          }

          .desc {
            margin-bottom: 20px;
          }

          .tags-wrapper {
            .tag {
              color: $raisin;
              border: 0;
              background-color: $honeyDew;
              font-size: 12px;
              padding-top: 5px;
            }
          }

          .date {
            color: $grey;
            font-size: 12px;
            margin-bottom: 5px;
          }
        }
      }

      &.column-item {
        box-shadow: none;
        position: relative;
        margin-bottom: 30px;

        .thumbnail {
          width: 90px;
          height: 90px;
          padding: 0;
          margin-right: 10px;
          display: inline-block;
          border-radius: 12px;
          vertical-align: top;
        }

        .text-wrapper {
          display: inline-block;
          width: calc(100% - 100px);
          padding: 0;
          margin: 0;
          vertical-align: top;

          .text {
            padding: 0;
            margin: 0;

            h3 {
              font-size: 14px;
              height: auto;
            }
          }
        }

        &::after {
          content: "";
          position: absolute;
          bottom: -15px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: $gainsboro;
        }

        &:last-child {
          &::after {
            content: none;
          }
        }
      }
    }
  }
}
</style>
