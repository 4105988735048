<template>
  <div id="eligible-item-cover">
    <div class="bg">
      <div class="bg-item circle">
        <svg width="661" height="706" viewBox="0 0 661 706" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.2">
            <g>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M424 682C613.986 682 768 527.986 768 338C768 148.014 613.986 -6 424 -6C234.014 -6 80 148.014 80 338C80 527.986 234.014 682 424 682ZM424 762C658.169 762 848 572.169 848 338C848 103.831 658.169 -86 424 -86C189.831 -86 0 103.831 0 338C0 572.169 189.831 762 424 762Z" fill="url(#paint0_linear_1432_10449)"/>
            </g>
            <g>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M424 528.584C529.257 528.584 614.585 443.257 614.585 338C614.585 232.743 529.257 147.415 424 147.415C318.744 147.415 233.416 232.743 233.416 338C233.416 443.257 318.744 528.584 424 528.584ZM424 608.584C573.44 608.584 694.585 487.439 694.585 338C694.585 188.56 573.44 67.4153 424 67.4153C274.561 67.4153 153.416 188.56 153.416 338C153.416 487.439 274.561 608.584 424 608.584Z" fill="url(#paint1_linear_1432_10449)"/>
            </g>
            <g>
              <path d="M519.253 338C519.253 390.607 476.607 433.253 424.001 433.253C371.394 433.253 328.748 390.607 328.748 338C328.748 285.394 371.394 242.748 424.001 242.748C476.607 242.748 519.253 285.394 519.253 338Z" fill="url(#paint2_linear_1432_10449)"/>
            </g>
          </g>
          <defs>
            <linearGradient id="paint0_linear_1432_10449" x1="424" y1="-86" x2="424" y2="762" gradientUnits="userSpaceOnUse">
              <stop stop-color="#919191"/>
              <stop offset="0.53125" stop-color="#828282" stop-opacity="0.442708"/>
              <stop offset="1" stop-color="#6346E3" stop-opacity="0"/>
              <stop offset="1" stop-color="#010101" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1432_10449" x1="424" y1="67.4153" x2="424" y2="608.584" gradientUnits="userSpaceOnUse">
              <stop stop-color="#919191"/>
              <stop offset="0.53125" stop-color="#828282" stop-opacity="0.442708"/>
              <stop offset="1" stop-color="#6346E3" stop-opacity="0"/>
              <stop offset="1" stop-color="#010101" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint2_linear_1432_10449" x1="424.001" y1="242.748" x2="424.001" y2="433.253" gradientUnits="userSpaceOnUse">
              <stop stop-color="#919191"/>
              <stop offset="0.53125" stop-color="#895C49" stop-opacity="0.442708"/>
              <stop offset="1" stop-color="#6346E3" stop-opacity="0"/>
              <stop offset="1" stop-color="#390808" stop-opacity="0"/>
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div class="bg-item line-1">
        <svg width="376" height="287" viewBox="0 0 376 287" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M-166.286 262.151C-159.36 271.693 -135.753 289.647 -96.7295 285.125C-47.9504 279.472 -119.285 247.642 -74.4209 203.562C-29.5563 159.483 20.5847 238.754 75.3385 208.454C130.092 178.154 120.438 46.3793 190.347 26.9254C260.255 7.47143 299.238 80.1673 345.951 74.4473C392.665 68.7273 343.725 -15.68 375.709 -13.6002" stroke="white" stroke-width="2"/>
        </svg>
      </div>

      <div class="bg-item line-2">
        <svg width="118" height="159" viewBox="0 0 118 159" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M117.047 -17.671C96.0884 -14.1482 51.9829 -5.01701 43.2289 3.32484C32.2864 13.7522 60.1291 79.0095 -4.04862 108.174C-68.2264 137.339 -22.0057 11.6233 -45.5292 44.5687C-69.0527 77.5141 -38.9247 141.585 -83.1922 155.48C-127.46 169.376 -166.482 113.603 -182.878 156.141" stroke="white" stroke-width="2"/>
        </svg>
      </div>

      <div class="bg-item line-red">
        <svg width="820" height="602" viewBox="0 0 820 602" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M190.844 435.386C173.824 605.385 60.1441 745.641 5.4315 794.519C442.575 802.874 1325.35 797.333 1359.3 708.336C1401.74 597.089 1166.41 562.531 985.5 571.617C804.587 580.704 966.935 351.362 834.677 297.335C702.419 243.309 771.193 -85.7181 583.86 25.2164C396.526 136.151 506.996 299.254 422.136 363.15C337.276 427.046 212.119 222.888 190.844 435.386Z" stroke="#DA0B0B" stroke-width="4"/>
        </svg>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12 col-md-5" v-if="!isDesktop">
          <img :src="item.thumbnail" :alt="`${t('CAM')} ${item.name}`" />
        </div>
        <div class="col-12 col-md-7">
          <div class="text-wrapper">
            <h1>{{ t('CAM') }} {{ item.name }}</h1>
            <p>{{ item.summary }}</p>
            <router-link :to="`${PATHS.SUBMIT_ORDER}?assetCategory=${item.assetCategory.id}`">
              <primary-button :text="t('CAM_NGAY!')" inverse />
            </router-link>
          </div>
        </div>
        <div class="col-12 col-md-5" v-if="isDesktop">
          <img :src="item.thumbnail" :alt="`${t('CAM')} ${item.name}`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';

import PATHS from '@/consts/paths';

import PrimaryButton from '@/components/PrimaryButton';

export default {
  name: 'EligibleItemCover',
  components: {
    PrimaryButton
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const isDesktop = computed(() => store.state.app.isDesktop);

    return {
      t,
      isDesktop,
      PATHS
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#eligible-item-cover {
  width: 100%;
  position: relative;
  padding: 100px 0;

  @media (max-width: 767px) {
    padding: 30px 0;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $barnRed;
    overflow: hidden;

    .bg-item {
      position: absolute;

      svg {
        width: 100%;
      }

      &.circle {
        top: 50%;
        right: 0;
        width: 33.33%;
        mix-blend-mode: color-dodge;
        transform: translateY(-50%);

        @media (max-width: 767px) {
          bottom: 0;
          top: auto;
          transform: translateY(50%);
          width: 100%;
        }
      }

      &.line-1 {
        top: 0;
        left: 0;
        width: 25%;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      &.line-2 {
        top: 0;
        left: 0;
      }

      &.line-red {
        bottom: 0;
        right: 0;

        @media (max-width: 767px) {
          width: 200%;
          transform: translateY(80%) translateX(10%);
        }
      }
    }
  }

  .container {
    text-align: left;
    position: relative;
    z-index: 1;
    max-width: 960px;
    color: $white;
  }

  .text-wrapper {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 15px;

    h1 {
      font-weight: 700;
      font-size: 48px;
      text-transform: capitalize;

      @media (max-width: 767px) {
        font-size: 28px;
      }
    }

    p {
      margin-top: 20px;
      color: $lavenderGrey;

      @media (max-width: 767px) {
        margin-top: 0;
      }
    }

    .primary-button {
      &::before {
        border: 0;
      }

      &::after {
        border: 0;
      }
    }
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 28px;
    }

    h3 {
      font-size: 16px;
    }
  }

  img {
    max-width: 100%;
  }
}
</style>
